import React, { useRef } from 'react';

import { Form, Formik, FormikHelpers } from 'formik';
import { Maybe } from 'graphql/jsutils/Maybe';
import { TFunction } from 'i18next';
import { isEmpty } from 'lodash';
import ReCAPTCHA from 'react-google-recaptcha';
import * as yup from 'yup';

import TextField from '@components/Formik/TextField';
import { notRotaryEmailRequiredSchema } from '@components/Formik/validation/fieldDefinitions';
import {
  isRequired,
  schemaField,
} from '@components/Formik/validation/schemaDefinitions';

import AccountCTAButtons from '../AccountCTAButtons';

import { useRecaptcha } from '@use-cases/account/hook';

import { FEATURE_TEST_EMAIL, isEnabled } from '@utils/features';
import { getLanguageFromURL } from '@utils/query-params';

import { useTranslation } from '@external/react-i18next';

const validationSchema = (t: TFunction) =>
  yup.object().shape({
    newEmail: notRotaryEmailRequiredSchema(t),
    confirmNewEmail: schemaField(t, yup.string(), isRequired).oneOf(
      [yup.ref('newEmail'), null],
      t(
        'account-changeemail.form.confirm_does_not_match_email',
        'Your emails do not match'
      )
    ),
    currentPassword: schemaField(t, yup.string(), isRequired),
  });

export interface FormValues {
  newEmail: string;
  confirmNewEmail: string;
  currentPassword: string;
}

interface Props {
  handleSubmit: (values: FormValues, token: Maybe<string>) => void;
  handleCancel: () => void;
}
const ChangeEmailForm: React.FC<Props> = ({ handleSubmit, handleCancel }) => {
  const { t } = useTranslation();

  const recaptchaRef = useRef(null);
  const {
    onRecaptchaChange,
    onRecaptchaExpired,
    resetCaptcha,
    token,
  } = useRecaptcha(recaptchaRef);

  const isTestEmailFeature = isEnabled(FEATURE_TEST_EMAIL);

  const handleFormSubmit = (
    values: FormValues,
    helpers: FormikHelpers<FormValues>
  ) => {
    handleSubmit(values, token);
    helpers.setSubmitting(false);
    resetCaptcha();
  };

  return (
    <Formik
      onSubmit={handleFormSubmit}
      initialValues={{
        newEmail: '',
        confirmNewEmail: '',
        currentPassword: '',
      }}
      validationSchema={validationSchema(t)}
    >
      {({ isSubmitting, isValid, touched }) => {
        const isSubmitButtonDisabled =
          isSubmitting ||
          (!token && !isTestEmailFeature) ||
          !isValid ||
          isEmpty(touched);

        return (
          <Form className="max-w-lg">
            <TextField
              name="newEmail"
              label={t('account-changeemail.form.new-email.label', 'New Email')}
              required
            />
            <TextField
              name="confirmNewEmail"
              label={t(
                'account-changeemail.form.confirm-new-email.label',
                'Confirm New Email'
              )}
              required
            />
            <TextField
              name="currentPassword"
              label={t(
                'account-changeemail.form.current-password.label',
                'Current Password'
              )}
              required
              password
            />
            {!isTestEmailFeature && (
              <ReCAPTCHA
                hl={getLanguageFromURL()}
                ref={recaptchaRef}
                sitekey={process.env.GATSBY_RECAPTCHA_SITE_KEY || ''}
                onChange={onRecaptchaChange}
                onExpired={onRecaptchaExpired}
              />
            )}
            <AccountCTAButtons
              isSubmitting={isSubmitting}
              handleCancel={handleCancel}
              isSubmitButtonDisabled={isSubmitButtonDisabled}
            />
          </Form>
        );
      }}
    </Formik>
  );
};

export default ChangeEmailForm;
