import { gql } from '@apollo/client';

export const languageFieldsFragment = gql`
  fragment LanguageFields on LanguageExtended {
    id
    name
    shortCode
  }
`;

export const fetchLanguages = gql`
  query fetchLanguages {
    languages {
      ...LanguageFields
    }
  }
  ${languageFieldsFragment}
`;
