import useAxios from 'axios-hooks';

export const useGetMailPreferences = () =>
  useAxios(
    {
      url: `${process.env.GATSBY_BACKEND_APP_BASE_URL}/en/restapi/mail_preferences`,
      withCredentials: true,
    },
    {
      useCache: false,
    }
  );

export const useUpdateMailPreferences = () =>
  useAxios(
    {
      url: `${process.env.GATSBY_BACKEND_APP_BASE_URL}/en/restapi/mail_preferences/update`,
      method: 'POST',
      withCredentials: true,
    },
    {
      manual: true,
    }
  );
