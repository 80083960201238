import React from 'react';

import { RouteComponentProps } from '@reach/router';

import { OneColumn } from '@components/Layouts/OneColumn';
import LinkPrevious from '@components/LinkPrevious';
import Loading from '@components/Loading';
import PasswordInstructions from '@components/Pages/ActivateAccount/PasswordInstructions';
import Title from '@components/Title';

import ChangePasswordForm, { FormValues } from './Form';

import { useNotifications } from '@use-cases/notifications';

import { useChangePassword } from '@repositories/profile/hooks';

import { localizedNavigate } from '@utils/localized-navigate';

import { useTranslation } from '@external/react-i18next';

interface Props extends RouteComponentProps {}
const ChangePassword: React.FC<Props> = () => {
  const { t } = useTranslation();
  const { addSuccess } = useNotifications();
  const [changePassword, { loading }] = useChangePassword();

  if (loading) {
    return <Loading />;
  }

  const handleSubmit = async ({ currentPassword, newPassword }: FormValues) => {
    const response = await changePassword({
      variables: { currentPassword, newPassword },
    });

    if (response) {
      addSuccess(
        t(
          'account-settings.change-password.success-message',
          'Success! Your new password has been saved.'
        )
      );
      localizedNavigate(`/account/settings`);
    }
  };
  return (
    <OneColumn className="mb-20">
      <LinkPrevious
        path="/account/settings"
        label={t('account.back-link.label', 'Account Settings')}
      />
      <Title>{t('account.change-password.title', 'Change Password')}</Title>
      <PasswordInstructions />
      <ChangePasswordForm
        handleSubmit={handleSubmit}
        handleCancel={() => localizedNavigate('/account/settings')}
      />
    </OneColumn>
  );
};

export default ChangePassword;
