import * as yup from 'yup';
import { TFunction } from 'i18next';

import {
  isRequired,
  schemaField,
} from '@components/Formik/validation/schemaDefinitions';
import { emailSchema } from '@components/Formik/validation/fieldDefinitions';
import { HasAccessToEmail } from '@domain/auth';

const validationSchema = (t: TFunction) =>
  yup.object().shape({
    email: emailSchema(t).when('hasAccess', {
      is: hasMessage => hasMessage === HasAccessToEmail.Yes,
      then: schemaField(t, yup.string(), isRequired),
    }),
    hasAccess: schemaField(t, yup.string(), isRequired),
  });

export default validationSchema;
