import React, { useEffect } from 'react';
import { useTranslation } from '@external/react-i18next';
import { useNotifications } from '@use-cases/notifications';
import { localizedNavigate } from 'src/utils/localized-navigate';
import { RouteComponentProps } from '@reach/router';
import { useFetchAccountInfo } from '../../ActivateAccount/query';
import { getQueryParams } from 'src/utils/query-params';
import Loading from 'src/components/Loading';
import { useMutation, gql } from '@apollo/client';

const useFinalizeChangeOfSigninEmail = () => {
  return useMutation(
    gql`
      mutation finalizeChangeOfSigninEmail($token: String!) {
        finalizeChangeOfSigninEmail(token: $token)
      }
    `,
    {
      refetchQueries: ['AuthGetUser'],
      awaitRefetchQueries: true,
    }
  );
};

interface Props extends RouteComponentProps {}
const VerifyChangeEmail: React.FC<Props> = () => {
  const [fetchAccountInfo] = useFetchAccountInfo();
  const { t } = useTranslation();
  const { addSuccess } = useNotifications();
  const { token } = getQueryParams();
  const [finalizeChangeOfSigninEmail] = useFinalizeChangeOfSigninEmail();

  useEffect(() => {
    if (typeof token === 'string') {
      fetchAccountInfo({ variables: { token } });
    }
  }, [token, fetchAccountInfo]);

  useEffect(() => {
    if (token) {
      finalizeChangeOfSigninEmail({ variables: { token } });
      addSuccess(
        t(
          'change-signin-email.success',
          'Success! Your sign-in email has been updated.'
        )
      );
      localizedNavigate('/account/settings');
    }
  }, [token]);

  return <Loading />;
};

export default VerifyChangeEmail;
