import { RECOGNITION_FLAG_OPTIONS } from './types';
import { TFunction } from 'i18next';

export const getOptions = (t: TFunction) => [
  {
    label: t(
      'account-settings.member-society.form.option1',
      'Yes, I want to share this information'
    ),
    value: RECOGNITION_FLAG_OPTIONS.YES,
  },
  {
    label: t(
      'account-settings.member-society.form.option2',
      "No, I don't want to share this information"
    ),
    value: RECOGNITION_FLAG_OPTIONS.NO,
  },
];
