import React from 'react';

import { RouteComponentProps } from '@reach/router';
import { Masonry } from 'masonic';

import { OneColumn } from '@components/Layouts/OneColumn';
import LinksBox from '@components/LinksBox';
import Loading from '@components/Loading';
import Title from '@components/Title';

import { FEATURE_DELEGATION, isEnabled } from '@utils/features';

import { useTranslation } from '@external/react-i18next';
import { useMenu } from '@hooks/menu';

interface Props extends RouteComponentProps {}

const AccountActions: React.FC<Props> = () => {
  const { t, i18n } = useTranslation();
  const { data, error, loading } = useMenu(
    'menu-notifications',
    i18n.language,
    true
  );
  const isDelegationEnabled = isEnabled(FEATURE_DELEGATION);

  return (
    <OneColumn>
      <Title>{t('account-actions-page.title', 'My Account Actions')}</Title>
      {loading && <Loading />}
      {error && <p>{error?.message}</p>}
      {data && !loading && !error && (
        <div className="flex flex-col justify-items-center items-center mt-11">
          <Masonry
            items={data}
            columnGutter={32}
            columnWidth={320}
            render={({ data: { description, label, children } }) => {
              return (
                <LinksBox
                  classes="w-auto"
                  header={label}
                  description={description}
                  items={
                    (isDelegationEnabled
                      ? children?.map(child => {
                          const temp = { ...child };
                          if (temp.path.includes('delegation')) {
                            temp.path = '/delegation/roles';
                          }
                          return temp;
                        })
                      : children) || []
                  }
                />
              );
            }}
          />
        </div>
      )}
    </OneColumn>
  );
};

export default AccountActions;
