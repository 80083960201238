import React from 'react';
import { SettingsRow } from '.';
import { useTranslation } from '@external/react-i18next';
import { Form, Formik } from 'formik';
import { LogLevel } from '@typings/graphql';
import RadioField from 'src/components/Formik/RadioField';
import { Button } from 'src/components/Button';
import {
  useGetMailPreferences,
  useUpdateMailPreferences,
} from '@hooks/mailPreferences';
import Loading from '@components/Loading';
import { useNotifications } from '@use-cases/notifications';
import { log } from '@hooks/logger';

enum EmailNotificationSetting {
  Suspended = '1',
  NotSuspended = '0',
}

interface FormValues {
  setting: EmailNotificationSetting | undefined;
}

const EmailNotifications = () => {
  const { t } = useTranslation();
  const { addError, addSuccess } = useNotifications();
  const [{ data, loading, error }] = useGetMailPreferences();
  const [
    { loading: updateLoading },
    updateMailPreferences,
  ] = useUpdateMailPreferences();

  if (loading) {
    return <Loading small />;
  }
  if (error || !data?.suspended) {
    return null;
  } else {
    log({
      level: LogLevel.Info,
      message: `Drupal: getMailPreferences, request to ${process.env.GATSBY_BACKEND_APP_BASE_URL}/en/restapi/mail_preferences`,
      other: {
        requestData: {
          useCache: false,
          withCredentials: true,
        },
        response: data,
      },
    });
  }

  const notificationSetting = data.suspended as EmailNotificationSetting;

  const options = [
    {
      label: t(
        'account-settings.group-notifications.form.option1',
        'Yes, I want to receive email notifications'
      ),
      value: EmailNotificationSetting.NotSuspended,
    },
    {
      label: t(
        'account-settings.group-notifications.form.option2',
        `No, I don't want to receive email notifications`
      ),
      value: EmailNotificationSetting.Suspended,
    },
  ];

  const handleSubmit = async ({ setting }: FormValues) => {
    try {
      const response = await updateMailPreferences({
        data: {
          suspended: setting,
        },
      });
      log({
        level: LogLevel.Info,
        message: `Drupal: updateMailPreferences, request to ${process.env.GATSBY_BACKEND_APP_BASE_URL}/en/restapi/mail_preferences/update`,
        other: {
          requestData: {
            manual: true,
            withCredentials: true,
          },
          response,
        },
      });

      addSuccess(
        t(
          'account-settings.group-notifications.update-success',
          'Success! You updated your email preferences.'
        )
      );
    } catch (error) {
      addError(
        t(
          'account-settings.group-notifications.update-error',
          'An error occurred updating your email preferences.'
        )
      );
    }
  };

  return (
    <SettingsRow
      label={t(
        'account-settings.group-notifications.label',
        'Discussion Groups notifications'
      )}
    >
      <div>
        {t(
          'account-settings.group-notifications.text1',
          'Receive Daily email notifications'
        )}
      </div>
      <span className="text-sm">
        {t(
          'account-settings.group-notifications.text2',
          'You can receive daily email notifications for the most recent activity in your discussion groups.'
        )}
      </span>
      <Formik
        initialValues={{ setting: notificationSetting }}
        enableReinitialize
        onSubmit={handleSubmit}
      >
        {() => {
          return (
            <Form>
              <RadioField label={''} name="setting" options={options} />
              <Button disabled={updateLoading}>
                {t(
                  'account-settings.group-notifications.form.button-label',
                  'Save'
                )}
              </Button>
            </Form>
          );
        }}
      </Formik>
    </SettingsRow>
  );
};

export default EmailNotifications;
