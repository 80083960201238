import React from 'react';
import TextField from '../../../Formik/TextField';
import { Formik, Form } from 'formik';
import { useTranslation } from '@external/react-i18next';
import { TFunction } from 'i18next';
import {
  schemaField,
  isRequired,
} from '../../../Formik/validation/schemaDefinitions';
import * as yup from 'yup';
import AccountCTAButtons from '../AccountCTAButtons';

const validationSchema = (t: TFunction) =>
  yup.object().shape({
    currentPassword: schemaField(t, yup.string(), isRequired),
    newPassword: schemaField(t, yup.string(), isRequired),
    confirmNewPassword: schemaField(t, yup.string(), isRequired).oneOf(
      [yup.ref('newPassword'), null],
      t(
        'form.field.confirm_does_not_match_password',
        'Your passwords do not match'
      )
    ),
  });

export interface FormValues {
  currentPassword: string;
  newPassword: string;
  confirmNewPassword: string;
}

interface Props {
  handleSubmit: (values: FormValues) => void;
  handleCancel: () => void;
}
const ChangePasswordForm: React.FC<Props> = ({
  handleSubmit,
  handleCancel,
}) => {
  const { t } = useTranslation();
  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={{
        currentPassword: '',
        newPassword: '',
        confirmNewPassword: '',
      }}
      validationSchema={validationSchema(t)}
    >
      {({ isSubmitting }) => {
        return (
          <Form className="max-w-lg">
            <TextField
              name="currentPassword"
              label={t(
                'account-settings.change-password.current-password.label',
                'Current Password'
              )}
              required
              password
            />
            <TextField
              name="newPassword"
              label={t(
                'account-settings.change-password.new-password.label',
                'New Password'
              )}
              required
              password
            />
            <TextField
              name="confirmNewPassword"
              label={t(
                'account-settings.change-password.confirm-password.label',
                'Confirm New Password'
              )}
              required
              password
            />
            <AccountCTAButtons
              isSubmitting={isSubmitting}
              handleCancel={handleCancel}
            />
          </Form>
        );
      }}
    </Formik>
  );
};

export default ChangePasswordForm;
