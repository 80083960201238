import React from 'react';

import { SettingsRow } from '..';
import RecognitionFlagForm from './Form';

import { useTranslation } from '@external/react-i18next';

import { RECOGNITION_FLAG_OPTIONS, RecognitionFormValues } from './types';

interface Props {
  isLoading: boolean;
  recognitionOptOutFlag?: boolean | null;
  handleUpdateRecognition: ({
    recognitionOptOutFlag,
  }: RecognitionFormValues) => Promise<void>;
}

const RecognitionFlag: React.FC<Props> = ({
  isLoading,
  recognitionOptOutFlag,
  handleUpdateRecognition,
}) => {
  const { t } = useTranslation();

  const defaultFormValues = {
    recognitionOptOutFlag: !recognitionOptOutFlag
      ? RECOGNITION_FLAG_OPTIONS.YES
      : RECOGNITION_FLAG_OPTIONS.NO,
  };

  return (
    <>
      <h2 className="mt-18">
        {t('account-settings.member-society.title', 'Sponsorship')}
      </h2>
      <SettingsRow
        label={t(
          'account-settings.member-society.label',
          'Share your Sponsorship Level Information'
        )}
      >
        <span className="text-sm">
          {t(
            'account-settings.member-society.info',
            '<p>Your name and club membership will be shared with everyone who has a My Rotary account (including non-members)</p><a href="/membership-society/recognition">View Membership Society</a>'
          )}
        </span>
        <RecognitionFlagForm
          handleSubmit={handleUpdateRecognition}
          defaultFormValues={defaultFormValues}
          loading={isLoading}
        />
      </SettingsRow>
    </>
  );
};

export default RecognitionFlag;
