import React from 'react';

import { Button } from '@components/Button';

import { useTranslation } from '@external/react-i18next';

interface AccountCTAButtonsProps {
  isSubmitting: boolean;
  handleCancel: () => void;
  isSubmitButtonDisabled?: boolean;
}

const AccountCTAButtons: React.FC<AccountCTAButtonsProps> = ({
  isSubmitting,
  handleCancel,
  isSubmitButtonDisabled,
}) => {
  const { t } = useTranslation();

  return (
    <div className="tablet:flex mt-16">
      <Button
        disabled={isSubmitButtonDisabled || isSubmitting}
        className="mb-5 tablet:mr-5 tablet:mb-0"
      >
        {t('account-settings.form.save-button-label', 'Save Changes')}
      </Button>
      <Button
        type="button"
        secondary
        disabled={isSubmitting}
        clickHandler={handleCancel}
      >
        {t('account-settings.form.cancel-button-label', 'Cancel')}
      </Button>
    </div>
  );
};

export default AccountCTAButtons;
