import { RefObject, useEffect, useState } from 'react';

import ReCAPTCHA from 'react-google-recaptcha';

export const useRecaptcha = (recaptchaRef: RefObject<ReCAPTCHA>) => {
  const [token, setToken] = useState<string | null>();

  useEffect(() => {
    setToken(recaptchaRef?.current?.getValue());
  }, [recaptchaRef]);

  const onRecaptchaChange = (reToken: string | null) => {
    setToken(reToken);
  };

  const resetCaptcha = () => {
    setToken(null);
    recaptchaRef.current?.reset();
  };

  const onRecaptchaExpired = () => {
    resetCaptcha();
  };

  return {
    onRecaptchaChange,
    onRecaptchaExpired,
    resetCaptcha,
    token,
  };
};
