import React from 'react';

import { useTranslation } from '@external/react-i18next';

const SecurityQuestionInstructions = () => {
  const { t } = useTranslation();
  return (
    <>
      <p className="text-big mb-2">
        {t(
          'account-settings.change-security-question.instructions.title',
          'The answer to your security question: '
        )}{' '}
      </p>
      <ul className="list-disc text-big mb-10">
        <li>
          {t(
            'account-settings.change-security-question.instructions.rule1',
            'Must be at least 4 characters'
          )}
        </li>
        <li>
          {t(
            'account-settings.change-security-question.instructions.rule2',
            'May not include your full password'
          )}
        </li>
        <li>
          {t(
            'account-settings.change-security-question.instructions.rule3',
            'May not include any part of the security question'
          )}
        </li>
        <li>
          {t(
            'account-settings.change-security-question.instructions.rule4',
            'May not include your full email address'
          )}
        </li>
      </ul>
    </>
  );
};

export default SecurityQuestionInstructions;
