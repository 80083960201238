import React from 'react';

import { gql, useMutation } from '@apollo/client';
import { RouteComponentProps } from '@reach/router';
import { Maybe } from 'graphql/jsutils/Maybe';

import { OneColumn } from '@components/Layouts/OneColumn';
import LinkPrevious from '@components/LinkPrevious';
import Title from '@components/Title';

import ChangeEmailForm, { FormValues } from './Form';

import { useNotifications } from '@use-cases/notifications';

import { localizedNavigate } from '@utils/localized-navigate';

import { useTranslation } from '@external/react-i18next';
import { useAppConfig } from '@hooks/appConfig';

export const useChangeSigninEmailMutation = () =>
  useMutation(gql`
    mutation changeSigninEmail(
      $newEmail: String!
      $currentPassword: String!
      $langcode: String!
      $recaptchaToken: String
    ) {
      changeSigninEmail(
        newEmail: $newEmail
        currentPassword: $currentPassword
        langcode: $langcode
        recaptchaToken: $recaptchaToken
      )
    }
  `);

interface Props extends RouteComponentProps {}
const ChangeEmail: React.FC<Props> = () => {
  const { t, i18n } = useTranslation();
  const langcode = i18n.language;
  const [changeSigninEmail] = useChangeSigninEmailMutation();
  const { addSuccess, addError } = useNotifications();
  const { user } = useAppConfig();

  const handleSubmit = async (
    { currentPassword, newEmail }: FormValues,
    recaptchaToken: Maybe<string>
  ) => {
    if (user?.login === newEmail) {
      addError(
        t(
          'account-settings.change-email.match-emails',
          'Your new email address cannot be the same as the current one'
        )
      );
      return;
    }
    await changeSigninEmail({
      variables: {
        langcode,
        currentPassword,
        newEmail,
        recaptchaToken,
      },
    });

    addSuccess(
      t(
        'account-settings.change-email.success-message',
        'Success! An email has been sent to {{ newEmail }}. Follow the instructions in the email to activate the new email for your account. ',
        { newEmail }
      ),
      { id: 'form.success' }
    );
    localizedNavigate(`/account/settings`);
  };
  return (
    <OneColumn className="mb-20">
      <LinkPrevious
        path="/account/settings"
        label={t('account.back-link.label', 'Account Settings')}
      />
      <Title>{t('account.change-email.title', 'Change Sign-in Email')}</Title>
      <p className="text-big mb-12">
        {t(
          'account-settings.change-email.info',
          'Please enter your current password and a new email to change the email associated with your account. '
        )}
      </p>
      <dl>
        <dt>
          {t(
            'account-changeemail.change-email.current-email-label',
            'Current Email'
          )}
        </dt>
        <dd className="text-small">{user?.login}</dd>
      </dl>
      <ChangeEmailForm
        handleCancel={() => localizedNavigate('/account/settings')}
        handleSubmit={handleSubmit}
      />
    </OneColumn>
  );
};

export default ChangeEmail;
