import {
  SetRecognitionFlagMutation,
  SetRecognitionFlagMutationVariables,
} from '@typings/operations';
import { gql, useMutation } from '@apollo/client';

export const setRecognitionFlagQuery = gql`
  mutation setRecognitionFlag(
    $individualId: String!
    $recognitionOptOutFlag: Boolean!
  ) {
    updateIndividual(
      input: {
        individualId: $individualId
        recognitionOptOutFlag: $recognitionOptOutFlag
      }
    ) {
      individualId
    }
  }
`;

export const useSetRecognitionFlag = () =>
  useMutation<SetRecognitionFlagMutation, SetRecognitionFlagMutationVariables>(
    setRecognitionFlagQuery,
    {
      refetchQueries: ['fetchRecognitionOptFlag'],
      awaitRefetchQueries: true,
    }
  );
