import React from 'react';

import { Router } from '@reach/router';

import RequireLogin from '@components/Auth/RequireLogin';
import AccountActions from '@components/Pages/Account/Actions';
import ChangeEmail from '@components/Pages/Account/ChangeEmail';
import VerifyChangeEmail from '@components/Pages/Account/ChangeEmail/VerifyChangeEmail';
import ChangePassword from '@components/Pages/Account/ChangePassword';
import ChangeSecurityQuestion from '@components/Pages/Account/ChangeSecurityQuestion';
import MappingByEmail from '@components/Pages/Account/MappingByEmail';
import SelectMemberStatus from '@components/Pages/Account/MappingByEmail/SelectMemberStatus';
import AccountSettings from '@components/Pages/Account/Settings';
import NotFound from '@components/Routing/NotFound';
import { titleTemplateParams } from '@components/Title/util';

import { Helmet } from '@external/react-helmet-async';
import { useTranslation } from '@external/react-i18next';

const AccountPage: React.FC<{
  pageContext: { languagePrefix: string };
}> = ({ pageContext: { languagePrefix } }) => {
  const { t } = useTranslation();
  const { suffix } = titleTemplateParams(t);

  return (
    <RequireLogin>
      <Helmet
        titleTemplate={`${t(
          'metadata.title.account',
          'Account'
        )}: %s ${suffix}`}
      />
      <Router basepath={`/${languagePrefix}account`}>
        <AccountActions path="/actions" />
        <AccountSettings path="/settings" />
        <ChangePassword path="/change-password" />
        <ChangeEmail path="/change-email" />
        <VerifyChangeEmail path="/verify-change-signin-email" />
        <ChangeSecurityQuestion path="/change-security-question" />
        <SelectMemberStatus path="/select-member-status" />
        <MappingByEmail path="/map-by-email" />
        <NotFound default />
      </Router>
    </RequireLogin>
  );
};

export default AccountPage;
