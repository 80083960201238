export enum RECOGNITION_FLAG_OPTIONS {
  YES = '1',
  NO = '0',
}

export interface RecognitionFormValues {
  recognitionOptOutFlag: RECOGNITION_FLAG_OPTIONS;
}

export interface RecognitionFlagFormProps {
  handleSubmit: (data: RecognitionFormValues) => void;
  defaultFormValues: RecognitionFormValues;
  loading: boolean;
}
