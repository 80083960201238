import React from 'react';
import TextField from '../../../Formik/TextField';
import { Formik, Form } from 'formik';
import { useTranslation } from '@external/react-i18next';
import { TFunction } from 'i18next';
import {
  schemaField,
  isRequired,
  hasMinLength,
} from '../../../Formik/validation/schemaDefinitions';
import * as yup from 'yup';
import Select from '../../../Formik/Select';
import { SecurityQuestion } from 'src/types/operations';
import AccountCTAButtons from '../AccountCTAButtons';

const validationSchema = (t: TFunction) =>
  yup.object().shape({
    question: schemaField(t, yup.string(), isRequired),
    answer: schemaField(t, yup.string(), hasMinLength(4), isRequired),
    currentPassword: schemaField(t, yup.string(), isRequired),
  });

export interface FormValues {
  question: string;
  answer: string;
  currentPassword: string;
}

interface Props {
  handleSubmit: (values: FormValues) => void;
  handleCancel: () => void;
  availableQuestions: Array<SecurityQuestion>;
}
const ChangeSecurityQuestionForm: React.FC<Props> = ({
  handleSubmit,
  handleCancel,
  availableQuestions,
}) => {
  const { t } = useTranslation();
  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={{
        question: '',
        answer: '',
        currentPassword: '',
      }}
      validationSchema={validationSchema(t)}
    >
      {({ isSubmitting }) => {
        return (
          <Form className="max-w-lg">
            <Select
              name="question"
              label={t(
                'account-settings.change-security-question.question-label',
                'Security Question'
              )}
              options={availableQuestions.map(({ id, text }) => ({
                value: id,
                label: text,
              }))}
              required
            />
            <TextField
              name="answer"
              label={t(
                'account-settings.change-security-question.question.label',
                'Security Question Answer'
              )}
              required
            />
            <TextField
              name="currentPassword"
              label={t(
                'account-settings.change-security-question.current-password.label',
                'Current Password'
              )}
              required
              password
            />
            <AccountCTAButtons
              isSubmitting={isSubmitting}
              handleCancel={handleCancel}
            />
          </Form>
        );
      }}
    </Formik>
  );
};

export default ChangeSecurityQuestionForm;
