// Libs
import React from 'react';

// Components
import { Form, Formik } from 'formik';
import RadioField from '@components/Formik/RadioField';
import { Button } from '@components/Button';

// Hooks
import { useTranslation } from '@external/react-i18next';

// Constants
import { getOptions } from './constants';

// Types
import { RecognitionFlagFormProps } from './types';

const RecognitionFlagForm = ({
  handleSubmit,
  defaultFormValues,
  loading,
}: RecognitionFlagFormProps) => {
  const { t } = useTranslation();
  return (
    <Formik
      initialValues={defaultFormValues}
      enableReinitialize
      onSubmit={handleSubmit}
    >
      {() => (
        <Form>
          <RadioField
            label={''}
            name="recognitionOptOutFlag"
            options={getOptions(t)}
          />
          <Button disabled={loading}>
            {t(
              'account-settings.group-notifications.form.button-label',
              'Save'
            )}
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default RecognitionFlagForm;
