import React, { useEffect, useState } from 'react';

import { ApolloError } from '@apollo/client';
import { RouteComponentProps } from '@reach/router';

import { OneColumn } from '@components/Layouts/OneColumn';
import Loading from '@components/Loading';

import { drupalAuthentication } from './drupalAuthentication';
import MappingByEmailForm, { FormValues } from './Form';

import { HasAccessToEmail, MAPPING_NOT_AVAILABLE_ERROR } from '@domain/auth';

import { getAuthenticationError } from '@use-cases/auth';
import { useNotifications } from '@use-cases/notifications';

import {
  useCreateIndividualFromSession,
  useMapUserAccount,
} from '@repositories/auth/hooks';
import { useDISLanguages } from '@repositories/disLanguage/hooks/useDISLanguages';

import { getDISLanguageFromTwoLetterCode } from '@utils/languages';
import { localizedNavigate } from '@utils/localized-navigate';

import { useTranslation } from '@external/react-i18next';
import { useAppConfig } from '@hooks/appConfig';
import { useLogger } from '@hooks/logger';

interface Props extends RouteComponentProps {}
const MappingByEmail: React.FC<Props> = () => {
  const { addLog } = useLogger();
  const { user, logout, refetch } = useAppConfig();
  const { t, i18n } = useTranslation();
  const { addSuccess } = useNotifications();
  const [createIndividualLoader, setCreateIndividualLoader] = useState(false);

  const langcode = i18n.language;

  const [mapUserAccount, { loading }] = useMapUserAccount();
  const [createIndividualFromSession] = useCreateIndividualFromSession();
  const { data: languagesData, loading: languagesLoading } = useDISLanguages();

  useEffect(() => {
    if (user?.individualId) {
      localizedNavigate('/');
    }
  }, [user]);

  if (loading || languagesLoading || createIndividualLoader) {
    return <Loading />;
  }

  const authenticateUserInApplication = async (successMessage: string) => {
    setCreateIndividualLoader(true);
    try {
      drupalAuthentication(
        successMessage,
        addLog,
        user,
        i18n,
        addSuccess,
        null
      );
    } catch (e) {
      const { message } = e as Error;
      addLog(getAuthenticationError(message));
    } finally {
      setCreateIndividualLoader(false);
      refetch();
      localizedNavigate('/');
    }
  };

  const createIndividualWithoutEmailMapping = async () => {
    setCreateIndividualLoader(true);

    try {
      await createIndividualFromSession({
        variables: {
          disLangcode: getDISLanguageFromTwoLetterCode(
            languagesData?.languages || [],
            langcode
          ).id,
        },
      });

      await authenticateUserInApplication(
        t(
          'account-mappingbyemail.form.failure',
          'We were unfortunately unable to correctly associate your account. Please <a href="/en/contact">contact us</a> for assistance connecting your account to your member record.'
        )
      );
    } catch (e) {
      const { message } = e as Error;
      addLog(getAuthenticationError(message));
    }
  };

  const handleSubmit = async ({ email, hasAccess }: FormValues) => {
    if (hasAccess === HasAccessToEmail.Yes) {
      try {
        const mapping = await mapUserAccount({
          variables: { disEmail: email },
        });

        if (!mapping.data?.mapByDisEmail) {
          await createIndividualWithoutEmailMapping();
          return;
        }

        await authenticateUserInApplication(
          t(
            'account-mapping.map-by-dis-email.success',
            'Success! Your My Rotary account is now setup.'
          )
        );
      } catch (err) {
        // Continue to show the loader before logging out so that the previous email form doesn't show up again
        setCreateIndividualLoader(true);

        const validationError = (err as ApolloError)?.graphQLErrors?.[0]
          .extensions;

        if (validationError?.code === 'Validation') {
          localStorage.setItem(
            MAPPING_NOT_AVAILABLE_ERROR,
            validationError?.validationErrorMessages[0]
          );

          logout('/login');
        }
      }

      return;
    }

    // If the user does not remember the previous email address,
    // a new account must be created with a message that
    // this account does not match their member record.
    await createIndividualWithoutEmailMapping();
  };

  return (
    <OneColumn>
      <h1>{t('account-mappingbyemail.title', 'Verify Your Previous Email')}</h1>
      <p>
        {t(
          'account-mappingbyemail.info',
          'We were not able to find a previous account associated with your provided sign up email address. Since we need to make sure our records are up to date we ask you to provide a previous email address your records might be associated with.'
        )}
      </p>
      <MappingByEmailForm handleSubmit={handleSubmit} />
    </OneColumn>
  );
};

export default MappingByEmail;
