// Libs
import React from 'react';

import { Form, Formik } from 'formik';

import { Button } from '@components/Button';
import RadioField from '@components/Formik/RadioField';
import TextField from '@components/Formik/TextField';

import validationSchema from './validation';

import { HasAccessToEmail } from '@domain/auth';
import { localizedNavigate } from '@utils/localized-navigate';

import { useTranslation } from '@external/react-i18next';

export interface FormValues {
  email: string;
  hasAccess: HasAccessToEmail;
}

interface MappingByEmailFormProps {
  handleSubmit: (values: FormValues) => void;
}

const MappingByEmailForm: React.FC<MappingByEmailFormProps> = ({
  handleSubmit,
}) => {
  const { t } = useTranslation();

  const hasEmailOption = [
    {
      value: 'yes',
      label: t(
        'account-mappingbyemail.form.has-previous-email',
        'I have previous email address'
      ),
    },
  ];

  const dontHaveEmailOption = [
    {
      value: 'no',
      label: t(
        'account-mappingbyemail.form.has-no-previous-email',
        "I don't have / don't remember this email address"
      ),
    },
  ];

  const handleBack = () => {
    localizedNavigate('/account/select-member-status');
  };

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={{
        email: '',
        hasAccess: HasAccessToEmail.Yes,
      }}
      validationSchema={validationSchema(t)}
    >
      {({ isSubmitting, values, isValid, dirty }) => {
        return (
          <Form className="max-w-lg">
            <RadioField
              name="hasAccess"
              required
              options={hasEmailOption}
              hasLabel={false}
            />

            {values.hasAccess === HasAccessToEmail.Yes && (
              <TextField
                name="email"
                label={t(
                  'account-mappingbyemail.form.email-label',
                  'Previous Email Address'
                )}
                required
              />
            )}

            <RadioField
              name="hasAccess"
              required
              options={dontHaveEmailOption}
              hasLabel={false}
            />

            <div className="mb-14 flex flex-col desktop:flex-row">
              <Button
                disabled={isSubmitting || !isValid || !dirty}
                className="mb-4 desktop:mb-0"
              >
                {t('account-mappingbyemail.form.button-label', 'Continue')}
              </Button>

              <Button
                secondary
                type="button"
                clickHandler={handleBack}
                className="desktop:ml-4"
                disabled={isSubmitting}
              >
                {t('back-link-text', 'Back')}
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default MappingByEmailForm;
