import React, { useEffect } from 'react';

import { RouteComponentProps } from '@reach/router';

import { OneColumn } from '@components/Layouts/OneColumn';
import Link from '@components/Link';
import Loading from '@components/Loading';
import { useSetRecognitionFlag } from '@components/Pages/Account/hooks';
import Title from '@components/Title';

import EmailNotifications from './EmailNotifications';
import RecognitionFlag from './RecognitionFlag';

import { useNotifications } from '@use-cases/notifications';

import { useFetchAccountSettings } from '@repositories/profile/hooks';

import { useTranslation } from '@external/react-i18next';
import { useAppConfig } from '@hooks/appConfig';
import { useMenu } from '@hooks/menu';

import { Drupal } from '@typings/drupal';

import {
  RECOGNITION_FLAG_OPTIONS,
  RecognitionFormValues,
} from './RecognitionFlag/types';

interface SettingsRowProps {
  label: string;
}

export const SettingsRow: React.FC<SettingsRowProps> = ({
  label,
  children,
}) => (
  <div className="flex w-full my-8 flex-col desktop:flex-row">
    <div className="font-bold desktop:w-1/4">{label}</div>
    <div className="desktop:w-3/4">{children}</div>
  </div>
);

interface Props extends RouteComponentProps {}

const AccountSettings: React.FC<Props> = () => {
  const { user } = useAppConfig();
  const { t, i18n } = useTranslation();
  const { addError, addSuccess } = useNotifications();
  const langcode = i18n.language;

  const { data: linkData, loading: linkLoading } = useMenu(
    'menu-my-rotary-account-services',
    langcode
  );

  const [
    updateRecognitionOptFlag,
    { loading: recognitionUpdating },
  ] = useSetRecognitionFlag();

  const [
    fetchUserPrimaryEmail,
    { data, loading: settingsLoading },
  ] = useFetchAccountSettings();

  useEffect(() => {
    if (user?.individualId) {
      fetchUserPrimaryEmail({
        variables: { individualId: user.individualId },
      });
    }
  }, []);

  const handleUpdateRecognition = async ({
    recognitionOptOutFlag,
  }: RecognitionFormValues) => {
    try {
      await updateRecognitionOptFlag({
        variables: {
          individualId: user?.individualId as string,
          recognitionOptOutFlag:
            // as we are receiving '1'/'0' - we should get Boolean here
            recognitionOptOutFlag === RECOGNITION_FLAG_OPTIONS.NO,
        },
      });
      addSuccess(
        t(
          'account-settings.member-society.update-success',
          'Success! You updated your recognition preferences.'
        )
      );
    } catch (error) {
      addError(
        t(
          'account-settings.member-society.update-error',
          'An error occurred updating your recognition preferences.'
        )
      );
    }
  };

  if (settingsLoading || linkLoading) {
    return <Loading />;
  }

  const newsletterLink = linkData?.find(
    ({ attributes }: Drupal.MenuItem) => attributes?.id === 'newsletters'
  );
  const ccOffersLink = linkData?.find(
    ({ attributes }: Drupal.MenuItem) => attributes?.id === 'cc-offers'
  );

  return (
    <OneColumn className="mb-20">
      <Title>{t('account-settings.title', 'Account Settings')}</Title>
      <h2 className="mt-12">
        {t('account-settings.security.title', 'Security')}
      </h2>
      <SettingsRow
        label={t('account-settings.change-password.label', 'Change Password')}
      >
        <div className="font-bold">******</div>
        <Link to="/account/change-password">
          {t(
            'account-settings.change-password-link.label',
            'Change your password'
          )}
        </Link>
      </SettingsRow>
      <SettingsRow
        label={t('account-settings.sign-in-email.label', 'Sign-In Email')}
      >
        <div>{user?.login}</div>
        <Link to="/account/change-email">
          {t(
            'account-settings.change-email-link.label',
            'Change your sign-in email'
          )}
        </Link>
      </SettingsRow>
      <SettingsRow
        label={t(
          'account-settings.security-question.label',
          'Security Question'
        )}
      >
        <div>{user?.currentSecurityQuestion}</div>
        <Link to="/account/change-security-question">
          {t(
            'account-settings.change-security-question.label',
            'Change your security question'
          )}
        </Link>
      </SettingsRow>
      <h2 className="mt-18">
        {t('account-settings.communications.title', 'Communications')}
      </h2>
      <SettingsRow
        label={t('account-settings.preferred-email.label', 'Preferred Email')}
      >
        <div>
          {data?.settings?.primaryEmail?.address ||
            t('account-settings.preferred-email.not-set', 'Not set')}
        </div>
        <span className="text-sm">
          {t(
            'account-settings.preferred-email.info',
            'Messages from Rotary International about your account are sent to your sign-in email. To change where these emails are delivered, you need to change your sign-in email above. All other non-account messages from Rotary International are sent to your preferred email. To change where these messages are delivered, update your preferred email and other contact information on your profile.'
          )}
        </span>
      </SettingsRow>
      <EmailNotifications />
      <SettingsRow
        label={t(
          'account-settings.do-not-contact.label',
          'Global Do not Contact'
        )}
      >
        <div>
          {t(
            'account-settings.do-not-contact.test',
            'Contact the Rotary Contact Center'
          )}
        </div>
        <span className="text-sm">
          {t(
            'account-settings.do-not-contact.info',
            'To be placed on the Global Do Not Contact list please send an email to <a href="mailto:rotarysupportcenter@rotary.org">rotarysupportcenter@rotary.org</a>'
          )}
        </span>
      </SettingsRow>
      <RecognitionFlag
        isLoading={recognitionUpdating}
        recognitionOptOutFlag={data?.settings?.recognitionOptOutFlag}
        handleUpdateRecognition={handleUpdateRecognition}
      />
      <h2 className="mt-18">
        {t('account-settings.services.title', 'Services')}
      </h2>
      <SettingsRow
        label={t('account-settings.newsletters.label', 'Newsletters')}
      >
        {newsletterLink && (
          <Link to={newsletterLink?.path}>{newsletterLink?.label}</Link>
        )}
      </SettingsRow>
      <SettingsRow
        label={t(
          'account-settings.credit-card-offers.label',
          'Credit Card offers'
        )}
      >
        {ccOffersLink && (
          <Link to={ccOffersLink?.path}>{ccOffersLink?.label}</Link>
        )}
      </SettingsRow>
    </OneColumn>
  );
};

export default AccountSettings;
