import axios, { AxiosError } from 'axios';
import { i18n } from 'i18next';

import { AuthUser } from '@domain/auth';
import { AddNotification } from '@domain/notifications';

import { getAuthenticationError } from '@use-cases/auth';

import { authenticateUserBackendApp } from '@repositories/auth/authExternalApps';
import { DrupalError, DrupalRes } from '@repositories/auth/typings';

import { FEATURE_LOGIN_AUTH_CONTROLLER, isEnabled } from '@utils/features';

import { AddLog } from '@hooks/logger';

import { DIS } from '@typings/dis';
import { LogLevel } from '@typings/operations';

export const drupalAuthentication = async (
  successMessage: string,
  addLog: AddLog,
  user: AuthUser,
  i18n: i18n,
  addSuccess: AddNotification,
  userInfo: DIS.SSOTicketResponse | null
) => {
  // (PECORE-865) An initiation to remove drupal authentication and its sync process.
  // Instead we expect user authorization data to be synced by system.
  const idToken = localStorage.getItem('idToken') || '';
  const authControllerEnabled = isEnabled(FEATURE_LOGIN_AUTH_CONTROLLER);

  const result = (await authenticateUserBackendApp(
    i18n.language,
    idToken,
    addLog,
    {
      initialCall: 'SelectMemberStatus',
      id: user?.individualId || '',
    },
    userInfo
  )()) as DrupalRes | DrupalError;

  if (result === 'ok') {
    addSuccess(successMessage);
    if (authControllerEnabled) {
      axios
        .get(
          `${process.env.GATSBY_BACKEND_APP_BASE_URL}/${i18n.language}/restapi/account/update`,
          {
            withCredentials: true,
          }
        )
        .then(async response => {
          addLog({
            level: LogLevel.Info,
            message: `Response from ${process.env.GATSBY_BACKEND_APP_BASE_URL}/${i18n.language}/restapi/account/update: ${response.data}`,
          });
        })
        .catch(error => {
          addLog({
            level: LogLevel.Error,
            message: `Error from ${process.env.GATSBY_BACKEND_APP_BASE_URL}/${
              i18n.language
            }/restapi/account/update: ${(error as AxiosError).message}: ${
              (error as AxiosError).stack
            }`,
          });
        });
    }
  } else {
    const {
      error_code: errorCode,
      error_message: errorMessage,
    } = result as DrupalError;
    addLog(getAuthenticationError(errorMessage, errorCode));
  }
};
